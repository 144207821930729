import AwesomeDebouncePromise from "awesome-debounce-promise";
import { MsalAuthProvider } from "react-aad-msal";

const AzureMapsScope = "https://atlas.microsoft.com//.default";

export default class MapService {
  apiSearchUrlTemplate: string;
  apiDistanceUrlTemplate: string;
  azureMapsClientId: string;
  AuthProvider: MsalAuthProvider;

  private searchAPI = async (url: string) => {
    const token = await this.AuthProvider.getAccessToken({ scopes: [AzureMapsScope]});

    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "x-ms-client-id": this.azureMapsClientId,
        "Content-Type": "application/json",
      },
    });
  };

  private debounceSearch = AwesomeDebouncePromise(this.searchAPI, 300, {
    key: () => "addressesSearch",
    onlyResolvesLast: true,
  });

  constructor(authProvider: MsalAuthProvider ) {
    this.azureMapsClientId = (window as any).env.azureMapsClientId
    this.AuthProvider = authProvider;
    this.apiSearchUrlTemplate =
      "https://atlas.microsoft.com/search/address/json?typeahead=true&api-version=1&query={query}&language=en-AU&countrySet=AU&view=Auto";
    this.apiDistanceUrlTemplate =
      "https://atlas.microsoft.com/route/directions/json?api-version=1&query={query}&routeRepresentation=summaryOnly&travelMode=car&view=Auto&traffic=false&routeType=shortest";
  }

  public async GetPossibleAddresses(query: string): Promise<SearchResult> {
    const requestUrl = this.apiSearchUrlTemplate.replace("{query}", query);

    let result = await this.debounceSearch(requestUrl);

    return (await result.json()) as SearchResult;
  }

  public async GetDistance(
    fromAddress: Position,
    toAddress: Position
  ): Promise<DistanceSummary> {
    let requestUrl = this.apiDistanceUrlTemplate.replace(
      "{query}",
      fromAddress.lat +
        "," +
        fromAddress.lon +
        ":" +
        toAddress.lat +
        "," +
        toAddress.lon
    );

    let result = await this.searchAPI(requestUrl);

    const data = (await result.json()) as RouteResult;
    if (data.routes.length === 0) {
      console.error("No Route found");
    }
    return data.routes[0].summary;
  }
}

export interface DistanceSummary {
  lengthInMeters: number;
  travelTimeInSeconds: number;
}

export interface RouteResult {
  routes: Route[];
}

export interface Route {
  summary: DistanceSummary;
}

export interface Position {
  lat: number;
  lon: number;
}

export interface Address {
  streetNumber: string;
  streentName: string;
  municipalitySubdivision: string;
  municipality: string;
  countrySecondarySubdivision: string;
  countrySubdivision: string;
  postalCode: string;
  countryCode: string;
  country: string;
  freeformAddress: string;
}

export interface AddressSearchResult {
  type: string;
  id: string;
  score: number;
  address: Address;
  position: Position;
}

export interface SearchResult {
  results: AddressSearchResult[];
}
