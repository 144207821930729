// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";

const AzureMapsScope = "https://atlas.microsoft.com//.default";

const azureAppId = (window as any).env.azureAppId;
const azureTenantId = (window as any).env.azureTenantId;

const authority = `https://login.microsoftonline.com/${azureTenantId}`;
//const scopes = `${azureAppId} ${AzureMapsScope}`;
const scopes = AzureMapsScope;

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: authority,
      clientId: azureAppId,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true,
      navigateToLoginRequestUrl: true      
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: [scopes]
  },
  {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + "/auth.html",
  }
);
