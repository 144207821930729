import React from "react";
import { Container, Button, Typography, CssBaseline } from "@material-ui/core";
import "./App.css";
import CommuteExpenseCalc from "./Components/CommuteExpense/commute-expense";
import Header from "./Components/Header/header";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { authProvider } from "./authProvider";
import AzureAD, {
  IAzureADFunctionProps,
  AuthenticationState,
} from "react-aad-msal";
import CommuteExpenseCalcService from "./services/commute-expense-calc";
import MapService from "./services/map-service";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#0E39FB",
    },
    type: "light",
  },
});

// Create singleton services here as a quasi-composition root. I'm not experienced enough in react
// to know the 'right' way to do it, but 'new'ing them inside CommuteExpenseCalc is not correct -
// it used to be done that way but led to an infitine loop detecting the newed up items as changed.
const expenseCalcService = new CommuteExpenseCalcService();
const mapService = new MapService(authProvider);

const App: React.FC = () => {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({
        login,
        authenticationState,
        error,
        accountInfo,
      }: IAzureADFunctionProps) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return (
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <div>
                  <Header provider={authProvider} accountInfo={accountInfo!} />
                  <Container>
                    <CommuteExpenseCalc authProvider={authProvider} expenseCalcService={expenseCalcService} mapService={mapService} />
                  </Container>
                </div>
              </ThemeProvider>
            );
          case AuthenticationState.Unauthenticated:
            return (
              <Container component="main" maxWidth="xs">
                {error && (
                  <p>
                    <span>
                      An error occurred during authentication, please try again!
                    </span>
                  </p>
                )}
                <p>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                  >
                    You are required to be logged in to access the Commute
                    Expense Calculator
                  </Typography>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={login}
                  >
                    Sign in
                  </Button>
                </p>
              </Container>
            );
          case AuthenticationState.InProgress:
            return (
                <Typography>Redirecting to sign in...</Typography>
            );
        }
      }}
    </AzureAD>
  );
};

export default App;