import { DistanceSummary } from './map-service'

export default class CommuteExpenseCalcService {
    public getExpenseDetails(
        distanceFromHomeToClient: DistanceSummary,
        distanceFromHomeToOffice: DistanceSummary,
        timesTravel: number,
        parking: number = 0,
        toll: number = 0
    ): [number, number] {
        const totalKilometers =
            (distanceFromHomeToClient.lengthInMeters / 1000 -
                distanceFromHomeToOffice.lengthInMeters / 1000 -
                25) *
            timesTravel

        const amount =
            totalKilometers * 0.68 +
            (isNaN(parking) ? 0 : parking) +
            (isNaN(toll) ? 0 : toll)

        return [totalKilometers, amount]
    }
}
