import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { IAccountInfo, MsalAuthProvider } from "react-aad-msal";

function Logo() {
  const theme = useTheme();
  const useMobile = useMediaQuery(theme.breakpoints.down('sm'));  
  return <img src={useMobile ? '/mobile-logo-white.png' : '/desktop-logo-white.png'}  alt="Telstra Purple Logo White" />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    signOut: { justifyContent: "center", padding: theme.spacing(2) },
    toolbar: {
      alignItems: "flex-start",
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0),          
      },
    }
  })
);

export default function Header(props: {
  provider: MsalAuthProvider;
  accountInfo: IAccountInfo;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleLogout = async () => {
    props.provider.logout();
    handleClose();
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
    <AppBar position="static">
      <Toolbar className={classes.toolbar}>
        <Logo/>
        <Typography className={classes.title}>
          Commute Expense Calculator
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={props.accountInfo.account.name}
                secondary={props.accountInfo.account.userName}
              />
            </ListItem>
            <Divider />
            <MenuItem onClick={handleLogout} className={classes.signOut}>
              <Typography color="secondary">Sign out</Typography>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
    </div>
  );
}
