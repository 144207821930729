import React, { useState, useEffect } from 'react';
import { Grid, Select, MenuItem, InputLabel, Typography, FormControl, FormHelperText, Button, InputAdornment, Input } from '@material-ui/core';
import { ExpenseDetails } from '../../models/expense-details';
import deburr from 'lodash/deburr';
import MapService, { SearchResult, Position, DistanceSummary } from '../../services/map-service';
import AutoCompleteTextField, { OptionType } from '../../shared/auto-complete';
import { StatesNameAddressMap, NetSuiteURI } from '../../shared/constants';
import CommuteExpenseCalcService from '../../services/commute-expense-calc';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MsalAuthProvider } from 'react-aad-msal';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { InfoTooltip } from './info-tooltip';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
	  flexGrow: 1
    },
    formControl: {
      margin: theme.spacing(0),
      minWidth: 80,
	},
	selectState: {
		flexGrow: 1,
	},
    selectEmpty: {
      marginTop: theme.spacing(3),
    },
	text: {
		display: 'block',
		textAlign: 'center'
	},
    header: {
      display: "flex",
	  alignItems: "stretch",
	  padding: theme.spacing(3, 0),
	},
	headerIcon: {
		fontSize: '1.5rem',
		verticalAlign: 'text-top',
		opacity: 0.6, 
		margin: theme.spacing(0,1),
	  },
	buttonsPanel : {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2)
	  },
	}));

const initialStage = {
	state: '',
	stateReadifyAddress: '',
	homeLocation: '',
	clientLocation: '',
	timesTravel: 1,
	parkingAmount: 0,
	tollAmount: 0,
	homeLocationPosition: undefined,
	clientLocationPosition: undefined,
	readifyOfficePosition: undefined,
	officeHasError: false,
};

export default function CommuteExpenseCalc(props: { authProvider: MsalAuthProvider, expenseCalcService: CommuteExpenseCalcService, mapService: MapService }) {
	const classes = useStyles();
	const expenseCalcService = props.expenseCalcService;
	const mapService = props.mapService;
	const [state, setState] = useState<ExpenseDetails>(initialStage);

	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [kilometersClaiming, setKilometersClaiming] = useState<number>(0);
	const [totalTimeTravelled, setTotalTimeTravelled] = useState<string>('0');
	const [formula, setFormula] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {

		function getValidation(): [boolean,string]{

			if(state.readifyOfficePosition === undefined)
			{
				return [false, "Office location is required"];
			}		
	
			if(state.homeLocationPosition === undefined)
			{
				return [false, "Home location is required"];
			}
	
			if(state.clientLocationPosition === undefined)
			{
				return [false, "Client location is required"];
			}
	
			if(state.timesTravel === undefined || state.timesTravel <= 0)
			{
				return [false, "Home location is required"];
			}
			return [true,""];
		}

		async function getDistance(
			fromAddress: Position,
			toAddress: Position
		): Promise<DistanceSummary> {
			return await mapService.GetDistance(fromAddress, toAddress);
		}	
		var validation = getValidation();	
		if (validation[0]) {
			if (error !== "") {
				setError("");
			}

			let homeToClient = getDistance(
				state.homeLocationPosition!,
				state.clientLocationPosition!
			);
			let homeToOffice = getDistance(state.homeLocationPosition!, state.readifyOfficePosition!);

			Promise.all([homeToClient, homeToOffice]).then(values => {
				const distanceFromHomeToClient = values[0];
				const distanceFromHomeToOffice = values[1];

				const [totalKilometers, amount] = expenseCalcService.getExpenseDetails(
					distanceFromHomeToClient,
					distanceFromHomeToOffice,
					state.timesTravel,
					state.parkingAmount,
					state.tollAmount
				);
				if (totalKilometers < 0) {
					setFormula("Unfortunately this distance doesn't qualify for an expense claim");
				} else {
					setFormula(
						`${(distanceFromHomeToClient.lengthInMeters / 1000).toFixed(2)}(Distance between Home and Client) 
						- ${(distanceFromHomeToOffice.lengthInMeters / 1000).toFixed(2)}(Distance between Home and Office) 
						- 25 * ${state.timesTravel} + ${state.tollAmount || 0}(Toll) + ${state.parkingAmount || 0}(Parking)`
					);

					setKilometersClaiming(Number(totalKilometers.toFixed(2)));

					setTotalAmount(Number(amount.toFixed(2)));

					setTotalTimeTravelled(
						(
							(distanceFromHomeToClient.travelTimeInSeconds / 60) *
							state.timesTravel
						).toFixed(1)
					);
				}
			});
		} else {
			setError(validation[1]);
			setFormula('');
			setKilometersClaiming(0);
			setTotalAmount(0);
			setTotalTimeTravelled('0');
			console.log(error);
		}
	}, [
		state.homeLocationPosition,
		state.clientLocationPosition,
		state.readifyOfficePosition,
		state.timesTravel,
		state.tollAmount,
		state.parkingAmount,
		expenseCalcService,
		mapService,
		error
	]);

	

	const navigateToExternalUrl = (url: string, shouldOpenNewTab: boolean = true) => shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;

	async function getSuggestions(value: string): Promise<OptionType[]> {
		const inputValue = deburr(value.trim()).toLowerCase();

		let addresses: SearchResult = await mapService.GetPossibleAddresses(inputValue);

		return addresses.results && addresses.results.length === 0
			? []
			: addresses.results.map(result => {
					return {
						label:
							result.address.freeformAddress +
							', ' +
							result.address.municipalitySubdivision,
						value: result.position
					};
			  });
	}


	function handleOfficeChanged(selectedState: string): void {
		const selectedStateAddress = StatesNameAddressMap.find(s => s.name === selectedState);
		setState({
			...state,
			state: selectedState,
			stateReadifyAddress: selectedStateAddress!.address,
			readifyOfficePosition: selectedStateAddress!.position,
			officeHasError: false
		});
	}

	function handleHomeAddressChanged(selectedAddress: OptionType): void {
		setState({
			...state,
			homeLocation: selectedAddress.label,
			homeLocationPosition: selectedAddress.value
		});
	}

	function handleClientAddressChanged(selectedAddress: OptionType): void {
		setState({
			...state,
			clientLocation: selectedAddress.label,
			clientLocationPosition: selectedAddress.value
		});		
	}

	function handleFocusChange(event: React.FocusEvent<HTMLInputElement>): void {
		validateForm();
	}

	function handleDaysChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		var value = parseFloat(event.target.value);
		setState({
			...state,
			timesTravel: value
		});
	}

	function handleTollChanged(event: React.ChangeEvent<HTMLInputElement>):void {
		var value = parseFloat(event.target.value);
		setState({
			...state,
			tollAmount: value
		});	
	}

	function validateForm(){
		let hasError = (state.state === "" || state.state === null || state.state === undefined);
		setState({
			...state,
			officeHasError: hasError,
		})
	}

	function handleParkingChanged(event: React.ChangeEvent<HTMLInputElement>):void {
		var value = parseFloat(event.target.value);
		setState({
			...state,
			parkingAmount: value
		});
	}

	const homeAddressAutoCompleteProps = {
		getSuggestions: getSuggestions,
		id: 'homeAddress',
		label: 'Home Address',
		title: 'Home Address',
		subtitle: 'Home Address',
		placeholder: 'Home Address',
		disabled: true,		
		onSelectedValue: handleHomeAddressChanged,
		onBlur: handleFocusChange
	};

	const clientAddressAutoCompleteProps = {
		getSuggestions: getSuggestions,
		id: 'clientAddress',
		label: 'Client Address',
		title: 'Client Address',
		subtitle: 'Client Address',
		placeholder: 'Client Address',
		disabled: true,
		onSelectedValue: handleClientAddressChanged,
		onBlur: handleFocusChange
	};

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Typography variant="h5">Commute Expense Calculator</Typography>
				<InfoTooltip 
				title="Expense Calculator" 
				description="Information displayed using this calculator is only an indication, the lodging of expense claims can be done via Netsuite">
					<InfoOutlinedIcon className={classes.headerIcon} />
				</InfoTooltip>
			</div>
			<Grid container spacing={3}>
				<Grid item xs={12}>		
				<FormControl className={classes.formControl} fullWidth={true} error={state.officeHasError} >
					<InputLabel>Office Location</InputLabel>
					<Select
						className={`${classes.selectState} select-state`}
						value={state.state}
						name="select-state"	
						fullWidth={true}						
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => validateForm()}					
						onChange={(e: any) => handleOfficeChanged(e.target.value)}
						inputProps={{ name: 'name', id: 'stateName' }}>
						{ StatesNameAddressMap.map(m => (
							<MenuItem value={m.name} key={m.name}>
								{m.address}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>{(state.state === null || state.state === "") ? "*Required" : ""} </FormHelperText>
				</FormControl>			
				</Grid>
				<Grid item xs={12}>
					<AutoCompleteTextField {...homeAddressAutoCompleteProps} />
				</Grid>
				<Grid item xs={12}>
					<AutoCompleteTextField {...clientAddressAutoCompleteProps} />
				</Grid>
				<Grid item xs={4}>
				<FormControl className={classes.formControl}>
				<FormHelperText>No. of trips</FormHelperText>
					<Input
						id="timesTravel"
						name="timesTravel"
						onChange={handleDaysChanged}
						value={state.timesTravel}
						type="number"
						placeholder="amount"
						startAdornment={<InputAdornment position="start">#</InputAdornment>}
					/>
				</FormControl>
				</Grid>
				<Grid item xs={4}>
				<FormControl className={classes.formControl}>
				<FormHelperText>Parking Paid</FormHelperText>
					<Input
						id="parking"
						onChange={handleParkingChanged}
						value={state.parkingAmount}
						type="number"
						placeholder="No of times travelled"
						startAdornment={<InputAdornment position="start">$</InputAdornment>}
					/>
					</FormControl>
				</Grid>
				<Grid item xs={4}>
				<FormControl className={classes.formControl}>
				<FormHelperText>Toll Paid</FormHelperText>
					<Input
						id="tollPaid"
						onChange={handleTollChanged}
						value={state.tollAmount}
						type="number"
						placeholder="No of times travelled"
						startAdornment={<InputAdornment position="start">$</InputAdornment>}
					/>
					</FormControl>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
				<Typography className={classes.text} variant="subtitle1">Calculation</Typography>
				<Typography className={classes.text}>
					<p><strong>{formula}</strong></p>
				</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
				<Typography className={classes.text} variant="subtitle1">Total amount</Typography>
				<Typography className={classes.text} variant="h5">
					<strong>${totalAmount}</strong> for{' '}
					<strong>{kilometersClaiming} kms</strong>
				</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
				<Typography className={classes.text} variant="subtitle1">Total Time travelled</Typography>
				<Typography className={classes.text} variant="h5">
					<strong>{totalTimeTravelled} minutes</strong>
				</Typography>
				</Grid>
			</Grid>		
			<Grid container justify="flex-end" spacing={1} className={classes.buttonsPanel}>
				<Grid item>
					<Button onClick={() => navigateToExternalUrl(NetSuiteURI, true)}>Open Netsuite</Button>
				</Grid>
			</Grid>
		</div>
	);
}
