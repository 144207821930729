import React, { useState } from "react";
import { MenuItem, Paper, Popper, TextField } from "@material-ui/core";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export interface OptionType {
  label: string;
  value: any
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1
		},
		container: {
			position: "relative"
		},
		suggestionsContainerOpen: {
			position: "absolute",
			zIndex: 1,
			marginTop: theme.spacing(1),
			left: 0,
			right: 0
		},
		suggestion: {
			display: "block"
		},
		suggestionsList: {
			margin: 0,
			padding: 0,
			listStyleType: "none"
		},
		divider: {
			height: theme.spacing(2)
		}
	})
);

function renderInputComponent(inputProps: any) {
	const { classes, inputRef = () => {}, ref, ...other } = inputProps;

	return (
		<TextField
			fullWidth			
			InputProps={{
				inputRef: node => {
					ref(node);
					inputRef(node);
				},
				classes: {
					input: classes.input
				}
			}}
			{...other}
		/>
	);
}

function renderSuggestion(
	suggestion: OptionType,
	{ query, isHighlighted }: Autosuggest.RenderSuggestionParams
) {
	const matches = match(suggestion.label, query);
	const parts = parse(suggestion.label, matches);

	return (
		<MenuItem selected={isHighlighted} component="div">
			<div>
			{parts.map((part,index) => (
				<span key={part.text + index.toString()} style={{ fontWeight: part.highlight ? 500 : 400 }}>
						{part.text}
					</span>
				))}
			</div>
		</MenuItem>
	);
}



export interface AutoCompleteProps {
	getSuggestions(value: string): Promise<OptionType[]>;
	label: string;
	id: string;
  	placeholder: string;
  	onSelectedValue(newValue: OptionType) : void;
  	onBlur(event: React.FocusEvent<HTMLInputElement>) : void;

}

export default function AutoCompleteTextField(props: AutoCompleteProps) {
	let classes = useStyles();
  let [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [state, setState] = React.useState({
		selectedValue: ""
  });
  
	const [stateSuggestions, setSuggestions] = React.useState<OptionType[]>([]);

  function getSuggestionValue(suggestion: OptionType) {
    props.onSelectedValue(suggestion);
    return suggestion.label;
  }

	const handleSuggestionsFetchRequested = async ({ value }: any) => {
		let suggestions = await props.getSuggestions(value);
		setSuggestions(suggestions);
	};

	const handleSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const handleChange = (name: keyof typeof state) => (
		event: React.ChangeEvent<{}>,
		{ newValue }: Autosuggest.ChangeEvent
	) => {
		setState({
			...state,
			[name]: newValue
    });
   
	};

	const autosuggestProps = {
		renderInputComponent,
		suggestions: stateSuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		getSuggestionValue,
		renderSuggestion
	};

	return (
		<div className={classes.root}>
			<Autosuggest
				{...autosuggestProps}
				inputProps={{
					classes,
					id: props.id,
					label: props.label,
					placeholder: props.placeholder,
					value: state.selectedValue,
					onChange: handleChange("selectedValue"),
					onBlur: (e: React.FocusEvent<HTMLInputElement>) => props.onBlur(e),
					inputRef: (node: HTMLInputElement | null) => {
						setAnchorEl(node);
					},
					InputLabelProps: {
						shrink: true
					}
				}}
				theme={{
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={options => (
					<Popper anchorEl={anchorEl} open={Boolean(options.children)}>
						<Paper
							square
							{...options.containerProps}
							style={{
								width: anchorEl ? anchorEl.clientWidth : undefined
							}}
						>
							{options.children}
						</Paper>
					</Popper>
				)}
			/>
		</div>
	);
}
