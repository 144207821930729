import {Position} from "../services/map-service"

export interface StateAddressMap {
	name: string;
	address: string;
	position: Position;
}

export const StatesNameAddressMap: StateAddressMap[] = [
    {
        name: "QLD",
        address: "275 George St, Brisbane QLD 4000",
        position: { lat: -27.4697491, lon: 153.0206685 }
    },
    {
        name: "VIC",
        address: "242 Exhibition Street, Melbourne VIC 3000",
        position: {lat: -37.80982, lon: 144.96983}
    },
    {
        name: "WA",
        address: "125 St Georges Terrace, Perth WA 6000",
        position: { lat: -31.9549937, lon: 115.8526679 }
    },
    {
        name: "NSW1",
        address: "1 Castlereagh Street, Sydney NSW 2000",
        position: { lat: -33.8663797, lon: 151.2075546 }
    },
    {
        name: "NSW2",
        address: "400 George Street, Sydney NSW 2000",
        position: {lat: -33.86911, lon: 151.20763}
    },
    {
        name: "NSW3",
        address: "18 Smith Street Parramatta NSW 2150",
        position: {lat: -33.815, lon: 151.0065}
    },
    {
        name: "SA",
        address: "30 Pirie St, Adelaide SA 5000",
        position: { lat: -34.9254024, lon: 138.5987283 }
    },
    {
        name: "ACT",
        address: "18 Mort St, Braddon ACT 2612",
        position: {lat: -35.27565, lon: 149.13114}
    },
    {
        name: "TAS",
        address: "70 Collins St, Hobart TAS 7000",
        position: {lat: -42.88134, lon: 147.32991}
    }
];

export const NetSuiteURI = "https://account.activedirectory.windowsazure.com/applications/signin/58c44701-f355-4a95-b157-18588cc581e7?tenantId=fc6a7adc-53de-44ad-88fc-af783bbb1d6e";