import { withStyles, Theme, Tooltip, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  type InfoTooltipProps = {
    title: string, 
    description: string,
    children?: JSX.Element[] | JSX.Element
  }

  export const InfoTooltip: FunctionComponent<InfoTooltipProps> = (props) => 
    <HtmlTooltip title={
        <React.Fragment>
            <Typography color="inherit">{props.title}</Typography>
            { props.description }
        </React.Fragment>} >
        <div>{ props.children }</div>
    </HtmlTooltip>;
